@import '../abstract/variables';
@import '../abstract/mixin';

.ant-btn.ant-btn-link {
  padding: 0;
  height: auto;

  span {
    text-decoration: underline;
  }

  &.td-link-bolder {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
}

.ant-btn {
  height: 48px;
  display: inline-flex;
  align-items: center;
  color: $primary;
  box-shadow: none;

  &.btn-underline span {
    border-bottom: 1px solid $primary;
  }

  &.btn-delete {
    color: $pink;
  }

  &.btn-delete[disabled],
  &.btn-delete[disabled]:hover {
    color: $blue-gray-30;
    border-color: $blue-gray-30;
  }

  &.btn-delete:hover,
  &.pink-color:hover {
    border-color: $pink;
  }
}

.drop-down-arrow {
  .ant-btn {
  }

  height: 45px;
  width: 70px;
}

.ant-btn-primary {
  background: $primary;
  border-color: $primary;
}

.ant-btn.ant-btn-block {
  display: flex;
  justify-content: center;
}

.ant-btn.ant-btn-primary {
  color: #fff;

  &[disabled] {
    color: $blue-gray-20;
  }
}

.absolute-btn {
  position: relative;

  div {
    position: absolute;
    margin: 20px;
  }
}

.back-btn {
  color: $color-high;
  display: flex;
  justify-content: center;
  @include ResponsiveMaxWidth(MD) {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .anticon {
    color: $color-medium;
    margin-top: 0.125em;
  }

  span {
    text-decoration: none !important;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
  }

  &:hover {
    background: #eeeeee;
  }

  &.with-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 8px;
    margin-top: 8px;
    margin-left: -40px;

    position: relative;
    top: 12px;
    left: 8px;

    @include ResponsiveMaxWidth(MD) {
      margin-top: 8px !important;
      margin-bottom: 8px !important;
      margin-left: -12px;
      position: unset;
    }
  }

  &.sidebar-top-client {
    margin-left: 20px !important;
    @include ResponsiveMaxWidth(XS) {
      margin-left: 0 !important;
    }
  }

  .custom-text {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: $color-high;
    @include ResponsiveMaxWidth(MD) {
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
    }
  }
}

.back-button {
  display: flex;
  flex-direction: column;
}

.back-btn-counselor {
  margin-left: -36px !important;
}

.back-text {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 16px;
  word-break: break-all;
  @include ResponsiveMaxWidth(XS) {
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
  }
}

// tdt-button
.tdt-button {
  display: inline-flex;
  line-height: 20px;
  height: 36px;
  align-items: center;
  letter-spacing: -0.04em;
  border-radius: 32px;
  cursor: pointer;
  padding: 0 8px;
  justify-content: center;

  &:focus {
    outline: none;
  }

  //&:hover {
  //    color: #7cb305 !important;
  //}

  &.tdt-button-center {
    justify-content: center;
  }

  //&.tdt-button-default {
  //    background: linear-gradient(
  //        179.03deg,
  //        #ffffff 3.27%,
  //        rgba(222, 222, 222, 0.514339) 77.65%,
  //        rgba(151, 151, 151, 0.33) 98.26%
  //    );
  //    border: 1px solid $blue-gray-600;
  //    color: $blue-gray-900;
  //    text-shadow: 1px 1px 0 $white;
  //}

  &.tdt-button-primary {
    background-color: $primary;
    border: 1px solid $primary;
    color: #fff;
  }

  //&.tdt-button-secondary {
  //    background-color: $pantone-213-c;
  //    border: 1px solid $pantone-213-c;
  //    color: $white;
  //    font-style: normal;
  //    font-weight: bold;
  //}
  //
  &.tdt-button-outline {
    background-color: #fff;
    border: 1px solid $color-outline;
    color: $primary;
  }

  &.tdt-button-outline-secondary {
    background-color: #fff;
    border: 1px solid $color-outline;
    color: $pink;
  }

  //&.tdt-button-dark {
  //    background-color: $blue-gray-900;
  //    border: 1px solid $blue-gray-600;
  //    color: $white;
  //}
  //
  //&.tdt-button-dashed {
  //    background-color: $white;
  //    border: 1px dashed $blue-gray-300;
  //    color: $blue-gray-300;
  //}
  //
  &.tdt-button-plain {
    background-color: transparent;
    border: none;
    color: $primary;
    text-decoration-line: underline;
    padding: 0;
    line-height: inherit;
    height: auto;
  }

  &.tdt-button-plain:hover {
    color: #400099;
    pointer-events: auto !important;
  }

  &.tdt-button-disabled {
    background: $bg-gray;
    border: 1px solid $bg-gray;
    color: $disabled-placeholder;
    cursor: not-allowed;
  }

  //&.tdt-button-disabled-2 {
  //    background: $blue-gray-200;
  //    color: $blue-gray-25;
  //    border: none;
  //    font-weight: bold;
  //    text-shadow: none;
  //}

  &.tdt-button-small {
    height: 24px;
  }

  &.tdt-button-big {
    height: 48px;
    padding: 0 16px;
  }

  //&.tdt-back-link {
  //    font-weight: bold;
  //    color: $blue-gray-900;
  //    margin-top: 16px;
  //    .tdt-icon {
  //        color: $pantone-2738-c;
  //        margin-right: 4px;
  //    }
  //}
}

//.tdt-link-button {
//    background-color: transparent;
//    color: $pantone-2738-c;
//    border: none;
//    cursor: pointer;
//    text-decoration: underline;
//    display: inline;
//    margin: 0;
//    padding: 0;
//
//    &:hover,
//    &:focus {
//        text-decoration: none;
//        outline: none;
//    }
//}

//.hover-plain {
//    pointer-events: auto;
//    color: #7cb305;
//}

.tdt-justify-center {
  justify-content: center;
}

.ant-btn-primary[disabled] {
  &,
  &.ant-btn-block,
  &.disable-gray-btn {
    border: 1px solid $blue-gray-20;
    color: $blue-gray-30 !important;
    font-weight: bold;
    background-color: $blue-gray-5 !important;
  }
}

.ant-pagination-item {
  border-radius: 4px;
}

.ant-pagination-item a {
  color: $primary;
  text-decoration-line: underline;
  text-transform: uppercase;
}

.ant-pagination-item-active a {
  background: $primary;
  color: $white;
  text-decoration: none;
}

.ant-pagination-item-active:hover a {
  text-decoration: underline;
}

.ant-pagination-item a:hover {
  text-decoration: underline;
}

.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: $white;
  text-decoration: none;
}

.ant-table-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-pagination.mini .ant-pagination-item {
  min-width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    font-size: 16px;
    line-height: 21px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: $blue-gray-100;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  font-size: 11px;
  line-height: 18px;
  top: unset;
  letter-spacing: 0.15em;
}

.ant-pagination-prev,
.ant-pagination-next {
  color: $blue-gray-60;

  &.ant-pagination-disabled {
    color: $blue-gray-20;
  }
}

.ant-btn-primary[disabled] {
  color: $blue-gray-30;
}

.ant-btn-primary:focus {
  background: $primary;
}

.ant-btn-primary:hover {
  opacity: 1;
}

.td-btn {
  width: 320px;
  height: 45px;
  font-size: 16px;
  justify-content: center;
  line-height: 21px;
  font-weight: 700;
  @include ResponsiveMaxWidth(XS) {
    max-width: 240px !important;
  }

  &.to-login-screen {
    @include ResponsiveMaxWidth(XS) {
      margin-bottom: 40px;
    }
  }
}

.td-btn-w140 {
  width: 140px;
  height: 45px;
  font-size: 16px;
  justify-content: center;
  line-height: 21px;
  font-weight: 700;
}

.td-btn-link {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  @include ResponsiveMaxWidth(XS) {
    margin-bottom: 16px;
  }
}

.tdt-button.btn-radius-4 {
  border-radius: 4px;
  width: 40px;
  height: 40px;
}

.interview-sheet-btn-radius {
  .tdt-button.btn-radius-4 {
    width: 48px;
  }
}

.btn-bg-none {
  background: none !important;
}

.csl-send-btn {
  border-radius: 8px;
}

.textarea-editable-send-btn {
  min-width: 112px;
  justify-content: center;
}

.double-btn {
  @include ResponsiveMaxWidth(MD) {
    font-size: 14px !important;
    line-height: 18px !important;
    width: 100% !important;
    height: 42px !important;
    display: flex;
    justify-content: center;
  }
}

.single-btn {
  @include ResponsiveMaxWidth(XS) {
    font-size: 14px;
    line-height: 18px;
    width: 100% !important;
    height: 42px !important;
    display: flex;
    justify-content: center;
  }
}

.tdt-button.tdt-button-disabled.disabled-link-plain {
  background: transparent;
  border-color: transparent;
  color: $disabled-placeholder !important;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  box-shadow: none !important;
}

.resend-email-btn {
  @include ResponsiveMaxWidth(XS) {
    max-width: 280px !important;
  }
}

.btn-restrict {
  border-radius: 8px;
  border-color: $blue-gray-20;
  color: $blue-gray-80;
  font-size: 14px;
  line-height: 24px;
  height: 44px;
  cursor: default;

  &:hover {
    border-color: $blue-gray-20;
    color: $blue-gray-80;
  }

  svg {
    color: $blue-gray-60;
  }
}

.btn-plain-disable {
  color: $blue-gray-50 !important;
  background-color: transparent !important;
  border-color: transparent !important;
  cursor: default !important;
}