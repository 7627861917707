@import '../abstract/mixin';

:root {
    --oem-color-primary: #032EFF;
    --oem-color-gray: #E5E5E5;
}

.oem-color-primary {
    color: var(--oem-color-primary);
}

.oem-color-gray {
    color: var(--oem-color-gray);
}

body {
    overflow-x: hidden;
}

#main-layout {
    display: flex;
    min-height: 100vh;
    //width: 100vw;

    @include ResponsiveMaxWidth(XL) {
        display: block;
    }
}

.td-header {
    text-align: center;
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 32px;
    color: #212121;
}

.d-none {
    display: none !important;
}

.login-container {
    //@include ResponsiveMaxWidth(XS) {
    //    padding: 16px;
    //}

    @include ResponsiveMaxWidth(SM) {
        padding: 16px;
    }
}
