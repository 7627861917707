@import '../abstract/variables';
@import '../abstract/mixin';

#sidebar {
  position: fixed;
  width: 220px;
  height: 100vh;
  background-color: $blue-gray-100;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;

  #logo {
    padding: 24px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    flex-grow: 0;
  }

  #user-info {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    flex-grow: 0;

    .svg-icon {
      color: $white;
    }

    .user-name {
      margin-left: 8px;
    }
  }

  .navigation {
    list-style: none;
    padding: 0;
    margin: 0;

    .navigation-item {
      height: 56px;
      display: flex;
      align-items: center;
      padding-left: 24px;
      position: relative;

      &.active,
      &:hover {
        background-color: $blue-gray-90;
        cursor: pointer;
      }

      &:not(.active) {
        cursor: pointer;
      }

      &.active,
      &:hover {
        .anticon {
          color: $white;
        }
      }

      .anticon {
        color: $blue-gray-40;
      }

      .badge {
        position: absolute;
        right: 24px;
        top: 20px !important;
        padding: 0 6px;
        height: 16px;
        line-height: 16px;
        border-radius: 8px;
        font-size: 12px;
        font-weight: bold;
        color: $white;
        background-color: $red-100;
      }
    }
  }

  #main-navigation {
    flex-grow: 1;
    overflow-y: auto;
  }

  #bottom-navigation {
    flex-grow: 0;
  }

  @include ResponsiveMaxWidth(MD) {
    display: none;
  }
  @include ResponsiveTablet(IpadPro) {
    display: flex;
    flex-direction: column;
  }
}

#sidebar-none-user {
  position: fixed;
  width: 220px;
  height: 100vh;
  background-color: $blue-gray-100;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  z-index: 999;

  #logo {
    padding: 24px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    flex-grow: 0;
  }

  #user-info {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    flex-grow: 0;

    .svg-icon {
      color: $white;
    }

    .user-name {
      margin-left: 8px;
    }
  }

  .navigation {
    list-style: none;
    padding: 0;
    margin: 0;

    .navigation-item {
      height: 56px;
      display: flex;
      align-items: center;
      padding-left: 24px;
      position: relative;

      &.active,
      &:hover {
        background-color: $blue-gray-90;
        cursor: pointer;
      }

      &:not(.active) {
        cursor: pointer;
      }

      &.active,
      &:hover {
        .anticon {
          color: $white;
        }
      }

      .anticon {
        color: $blue-gray-40;
      }

      .badge {
        position: absolute;
        right: 24px;
        top: 20px;
        padding: 0 6px;
        height: 16px;
        line-height: 16px;
        border-radius: 8px;
        font-size: 12px;
        font-weight: bold;
        color: $white;
        background-color: $red-100;
      }
    }
  }

  #main-navigation {
    flex-grow: 1;
    overflow-y: auto;
  }

  #bottom-navigation {
    flex-grow: 0;
  }
}

#sidebar-responsive {
  position: fixed;
  width: 256px;
  height: 100%;
  background-color: #fff;
  color: $blue-gray-80;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  padding-top: 80px;
  border-radius: 0 20px 20px 0;

  #logo {
    padding: 37px 24px 24px 24px;
    //font-size: 20px;
    //line-height: 30px;
    //font-weight: 700;
    //flex-grow: 0;
  }

  #user-info {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    flex-grow: 0;

    .svg-icon {
      color: $white;
    }

    .user-name {
      margin-left: 8px;
    }
  }

  .navigation {
    list-style: none;
    padding: 0;
    margin: 0;

    .navigation-item {
      height: 56px;
      display: flex;
      align-items: center;
      padding-left: 24px;
      position: relative;

      &.active,
      &:hover {
        background-color: $blue-gray-90;
        cursor: pointer;
      }

      &:not(.active) {
        cursor: pointer;
      }

      &.active,
      &:hover {
        .anticon {
          color: $white;
        }
      }

      .anticon {
        color: $blue-gray-40;
      }

      .badge {
        position: absolute;
        right: 24px;
        top: 20px;
        padding: 0 6px;
        height: 16px;
        line-height: 16px;
        border-radius: 8px;
        font-size: 12px;
        font-weight: bold;
        color: $white;
        background-color: $red-100;
      }
    }
  }

  #main-navigation {
    flex-grow: 1;
    overflow-y: auto;
  }

  #bottom-navigation {
    flex-grow: 0;
  }
}

#sidebar-calendar {
  position: fixed;
  width: 220px;
  height: 100vh;
  background-color: $white;
  color: $blue-gray-60;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  border-right: 1px solid $blue-gray-20;

  #logo {
    padding: 24px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    flex-grow: 0;
  }

  #user-info {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    flex-grow: 0;

    .svg-icon {
      color: $white;
    }

    .user-name {
      margin-left: 8px;
    }
  }

  .navigation {
    list-style: none;
    padding: 0;
    margin: 0;

    .navigation-item {
      height: 56px;
      display: flex;
      align-items: center;
      padding-left: 24px;
      position: relative;

      &.active,
      &:hover {
        background-color: $blue-gray-5;
        color: $red-100;
        cursor: pointer;
      }

      &:not(.active) {
        cursor: pointer;
      }

      &.active,
      &:hover {
        .anticon {
          color: $red-100;
        }
      }

      .anticon {
        color: $blue-gray-40;
      }

      .badge {
        position: absolute;
        right: 24px;
        top: 20px;
        padding: 0 6px;
        height: 16px;
        line-height: 16px;
        border-radius: 8px;
        font-size: 12px;
        font-weight: bold;
        color: $white;
        background-color: $red-100;
      }
    }
  }

  #main-navigation {
    flex-grow: 1;
    overflow-y: auto;
  }

  #bottom-navigation {
    flex-grow: 0;
  }
}

#sidebar-top {
  background: $blue-gray-100;
  height: 56px;

  #logo {
    padding: 13px 13px 13px 24px;
  }
}

.none-sidebar-user {
  background-color: $blue-gray-90 !important;

  .navigation {
    .navigation-item {
      &.active,
      &:hover {
        background-color: $blue-gray-80 !important;
      }
    }
  }
}

.responsive-title {
  //background: $blue-gray-100;
  background-color: #fff;
  box-shadow: 0 4px 16px 0 #00000014;
  border-radius: 0 0 10px 10px;

  width: 100%;
  height: 54px;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  .menu-icon {
    left: 16px;
    top: 32%;
    position: absolute;
    color: #000;
  }

  .logo-td {
    width: 100%;
  }

  @include Responsive(MD) {
    display: none;
  }
  @include ResponsiveMaxWidth(MD) {
    position: fixed;
    width: 100%;
    z-index: 999999;
  }
}

.ant-drawer-content-wrapper {
  box-shadow: none !important;
}

.ant-drawer-content {
  background-color: transparent;
}

.ant-drawer-mask {
  background-color: rgba(0, 0, 0, 0.16);
}

.ant-drawer-body {
  padding: 0;

  #sidebar-responsive {
    .navigation-item {

      &.active {
        font-weight: 700;
      }

      &.active, &:hover {
        background-color: transparent;
        color: var(--oem-color-primary);

        .anticon {
          color: inherit;
        }
      }
    }

    #user-info {
      .svg-icon {
        color: $blue-gray-40;
      }

      .navigation-item {
        &.active, &:hover {
          .svg-icon {
            color: var(--oem-color-primary) !important;
          }
        }
      }
    }
  }
}

.dot-count {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: $red-100;
    right: -5px;
    top: -1px;
  }

  &:before {
    width: 12px;
    height: 12px;
    background: $white;
    display: block !important;
    content: '';
    position: absolute;
    border-radius: 100%;
    right: -7px;
    top: -3px;
  }
}

.dot-unread-message {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: $red-100;
  display: inline-block;
  margin: 0 0 2px 4px;

  &.abs-left {
    position: absolute;
    left: 4px;
    //top: 50%;
    top: calc(50% - 4px);
  }
}

.number-unread-message {
  padding: 0 6px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  color: $white;
  background-color: $red-100;
  position: relative;
  bottom: 2px;
  margin-left: 10px;
}

.counselor-for-message-table {
  &.aside-table.collapse-table .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    padding-left: 24px;

    &.ant-table-column-sort {
      padding-right: 0;
    }

    &.text-align-right {
      padding-left: 0;
    }
  }
}

.ant-drawer-left {
  @include ResponsiveMaxWidth(MD) {
    z-index: 9999999;
  }
}

.logo-text {
  svg {
    height: 18px;
    fill: #fff;
  }
}

.main-item:last-child {
  position: absolute !important;
  bottom: 12px;
  left: 0;
  width: 100%;
}

#sidebar-none-user, #sidebar {
  &.sidebar-light {
    background-color: #fff !important;
    color: $blue-gray-80;
    border-right: 1px solid $blue-gray-20;

    .navigation {
      padding: 0 8px;

      .navigation-item {
        border-radius: 8px;
        height: 48px;
        margin: 4px 0;
        padding-left: 16px;

        &.active, &:hover {
          background-color: $red-100 !important;
          color: #fff;

          .badge {
            color: $red-100;
            background-color: #fff;
          }
        }

        .badge {
          right: 16px;
          top: 16px;
          color: #fff;
          background-color: $red-100;
          min-width: 20px;
        }
      }
    }

    #user-info .svg-icon {
      color: $blue-gray-40;
    }

    #logo {
      .anticon svg {
        //color: $red-100;
      }
    }
  }
}
#sidebar.sidebar-light .navigation .navigation-item{
  height: 56px;
}

#container {
  &.container-user {
    margin-left: 280px;

    .float-bar {
      left: 280px
    }

    @include ResponsiveMaxWidth(MD) {
      margin: 0;

      .float-bar {
        left: 0;
      }
    }
  }
}

#sidebar.sidebar-user {
  width: 280px;
  &.sidebar-light {
    border-radius: 0 30px 30px 0;
    box-shadow: 4px 0 16px 0 rgba(0, 0, 0, 0.1);
    border-right-color: transparent;
    z-index: 9999;

    .navigation {
      padding: 0 16px;

      .navigation-item{
        height: 56px;
        margin: 4px 0;

        &.active, &:hover {
          background-color: var(--oem-color-primary) !important;
          color: #fff;

          .badge {
            color: var(--oem-color-primary);
            background-color: #fff;
          }
        }
      }
    }
  }
}

#sidebar {
  .main-item:last-child {
    left: 8px;
    right: 8px;
    width: unset;
  }

  &.sidebar-user {
    .main-item:last-child {
      left: 16px;
      right: 16px;
      bottom: 24px;
    }
  }
}

#user-info {
  .navigation-item {
    &:hover, &.active {
      .svg-icon {
        color: #fff !important;
      }
    }
  }
}
